import React, { Component } from "react";
import ChordIdentification from "./chord-identification/ChordIdentification.js";
import { CSSTransition } from "react-transition-group";

import "./App.css";

const transitionDuration = 500;

const defaultStyle = {
  transition: `opacity ${transitionDuration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentExercise: null,
      in: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ in: true });
    }, 50);
  }

  handleSelectExercise() {
    this.setState({
      in: false,
      currentExercise: {
        id: 0,
        rootNote: null,
        choices: ["min", "maj"],
        pitchArrangement: "harmony",
      },
    });
  }

  // @autobind
  handleExitExercise = () => {
    this.setState({
      currentExercise: null,
    });
    setTimeout(() => {
      this.setState({ in: true });
    }, 50);
  };

  render() {
    const home = (
      <div className="Menu-content">
        <p>
          Identify{" "}
          <a
            href="https://en.wikipedia.org/wiki/Minor_chord"
            target="_blank"
            rel="noopener noreferrer"
          >
            minor
          </a>{" "}
          and{" "}
          <a
            href="https://en.wikipedia.org/wiki/Major_chord"
            target="_blank"
            rel="noopener noreferrer"
          >
            major
          </a>{" "}
          chord.
        </p>
        <button
          className="Start-button"
          onClick={() => this.handleSelectExercise()}
        >
          <span>Start </span>
        </button>
      </div>
    );
    let config = this.state.currentExercise;

    return config === null ? (
      <CSSTransition in={this.state.in} timeout={500}>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {home}
          </div>
        )}
      </CSSTransition>
    ) : (
      <ChordIdentification
        pitchArrangement={config.pitchArrangement}
        choices={config.choices}
        exitHandler={this.handleExitExercise}
      />
    );
  }
}

export default App;
