import React, { Component } from "react";
import Proptypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { CSSTransition } from "react-transition-group";

import "./HallOfFame.css";

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const starRating = (score) => {
  let stars = Array(5).fill(false);
  let count;
  count = Math.floor((score * 5) / 100);
  for (let i = 0; i < count; i++) {
    stars[i] = true;
  }
  return stars;
};

class HallOfFame extends Component {
  constructor(props) {
    super(props);
    this.state = { in: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ in: true });
    }, 50);
  }

  render() {
    let { score, newGameHandler, exitHandler } = this.props;
    return (
      <div>
        <CSSTransition in={this.state.in} timeout={500}>
          {(state) => (
            <div
              className="HallOfFame-content"
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <h1>Game over!</h1>
              <p>Your final score is {score.toFixed(2)} %</p>
              <div className="HallOfFame-stars">
                {starRating(score).map((selected, index) => (
                  <FontAwesome
                    name="star"
                    key={index}
                    className={`${selected === true ? "checked" : ""}`}
                  />
                ))}
              </div>
              <div>
                <button
                  className="HallOfFame-button"
                  onClick={() => newGameHandler()}
                >
                  Restart
                </button>
              </div>
              <div>
                {" "}
                <button
                  className="HallOfFame-button"
                  onClick={() => exitHandler()}
                >
                  Quit
                </button>
              </div>
            </div>
          )}
        </CSSTransition>
      </div>
    );
  }
}

HallOfFame.propTypes = {
  exitHandler: Proptypes.func.isRequired,
  newGameHandler: Proptypes.func.isRequired,
  score: Proptypes.number.isRequired,
};

export default HallOfFame;
